import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import classNames from 'classnames';

const EmailForm = dynamic(() => import('./../EmailForm'), {
  ssr: false,
});

const NAVY = 'navy';
const GOLD = 'gold';
const CREAM = 'cream';
const WHITE = 'white';

export function GridEmailCapture({ cmsData, setGridSuccessSignUp }) {
  const {
    advisory,
    anchor_id: anchorId,
    background_color: backgroundColor,
    cta_theme: ctaTheme,
    email_placeholder: emailPlaceholder,
    input_color: inputColor,
    klaviyo_custom_property: klaviyoCustomProperty,
    success_advisory: successAdvisory,
    uid,
  } = cmsData ?? {};
  const showNewsletter = useSelector((state) => state.featureFlags.newsletter);

  const [successSignUp, setSuccessSignUp] = useState(false);

  useEffect(() => {
    if (
      successSignUp &&
      setGridSuccessSignUp &&
      typeof setGridSuccessSignUp === 'function'
    ) {
      setGridSuccessSignUp(true);
    }
  }, [successSignUp]);

  const borderTextClass = classNames({
    'text-primary border-primary': inputColor === NAVY,
    'text-cream border-cream': inputColor === CREAM,
    'text-white border-white': inputColor === WHITE,
  });

  return showNewsletter ? (
    <aside className="w-100 h-100 position-relative" id={anchorId}>
      <div
        className={classNames(
          `position-relative d-flex flex-row flex-wrap align-items-center justify-content-center px-lg-20 h-100`,
          {
            'bg-primary': backgroundColor === NAVY,
            'bg-secondary': backgroundColor === GOLD,
            'bg-cream': backgroundColor === CREAM,
            'bg-white': backgroundColor === WHITE,
          },
          borderTextClass
        )}
      >
        {successSignUp ? (
          <div className="font-geo fs-10 fs-md-14 px-4 text-center">
            {successAdvisory}
          </div>
        ) : (
          <div className="px-4 w-100 d-flex flex-column justify-content-center align-items-center">
            <h3 className="font-geo w-100 w-sm-75 fs-6 fs-sm-8 mt-3 mb-0 mt-md-0 mx-auto text-center">
              {advisory}
            </h3>
            <div className="w-100 w-sm-75">
              <EmailForm
                className={borderTextClass}
                controlId={uid}
                ctaVariant={ctaTheme}
                emailFormPlaceholder={emailPlaceholder}
                formClassName="pt-3 pt-sm-8 bg-transparent"
                formGroupClassName={[
                  borderTextClass,
                  'font-geo text-uppercase',
                ]}
                klaviyoCustomProperty={klaviyoCustomProperty}
                labelClassName={borderTextClass}
                setSuccessSignUp={setSuccessSignUp}
              />
            </div>
          </div>
        )}
      </div>
    </aside>
  ) : null;
}

export default GridEmailCapture;
