import { useState } from 'react';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import Image from 'components/Image';
import Link from 'components/Link';
import Video from 'components/Video';
import CloseIcon from 'svg/CloseIcon/index.jsx';
import xss from 'utils/xss';
import styles from './FullWidthVideo.module.scss';

function FullWidthVideo({
  content_block: {
    anchor_id: anchorId,
    background_action: backgroundAction,
    background_color: backgroundColor,
    background_image: backgroundImage,
    background_image_alt_text: altText,
    background_video: backgroundVideo,
    cta_label: ctaLabel,
    cta_url: ctaUrl,
    header,
    header_type: headerType,
    link_label: linkLabel,
    link_url: linkUrl,
    over_video_text_color: overVideoTextColor = 'White',
    play_icon: playIcon,
    text_color: textColor = 'Navy',
    text_content: textContent,
    use_small_heading: useSmallHeading = false,
    video,
  },
}) {
  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [isHovered, setIsHovered] = useState(null);

  const playVideo = (src) => () => {
    setVideoSrc(src);
    setModalIsOpen(true);
  };

  const hideVideo = () => {
    setModalIsOpen(null);
    setVideoSrc(null);
  };

  const HeaderTag = headerType || 'h2';
  const bgColor = backgroundColor || 'inherit';
  const useDarkText = textColor === 'Navy';
  const useDarkTextVideoColor = overVideoTextColor === 'Navy';
  const showCtaDiv = ctaUrl || linkUrl;
  const showNonVideoContent = showCtaDiv || header || textContent;

  const linkStyle = ctaUrl
    ? `${styles.link} ${styles.marginLeft}`
    : `${styles.link}`;

  const applyZoomEffect = backgroundAction && backgroundAction === 'Zoom';

  return video?.url ? (
    <section
      className={styles.section}
      id={anchorId}
      style={{ background: bgColor }}
    >
      <div className="m-auto position-relative">
        <div
          className={classNames(
            'position-relative mb-0',
            styles.hero_video_container
          )}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className={classNames(styles.video_container)}>
            <div className={classNames(styles.spacing_dummy)} />
            <div
              className={classNames(styles.media_content, {
                [styles.image_transition]: applyZoomEffect,
                [styles.image_grow]: applyZoomEffect && isHovered,
              })}
            >
              {applyZoomEffect && backgroundImage?.url ? (
                <Image
                  fill
                  priority
                  src={backgroundImage?.url}
                  alt={altText}
                  objectFit="cover"
                />
              ) : (
                <Video
                  autoPlay
                  loop
                  muted
                  playsInline
                  desktopSrc={backgroundVideo?.url ?? video?.url}
                  mobileSrc={backgroundVideo?.url ?? video?.url}
                />
              )}
            </div>
            {playIcon ? (
              <div className="position-absolute h-100 w-100 top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex flex-row">
                  <button
                    type="button"
                    className="position-relative d-flex justify-content-center align-items-center btn p-6 px-20"
                    onClick={playVideo(video?.url)}
                  >
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faCirclePlay}
                      style={{ width: 80, height: 80 }}
                    />
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {showNonVideoContent && (
          <div
            className={classNames(
              'bottom-0 start-0 end-0 d-flex flex-column align-items-left justify-content-left pb-14 pb-lg-6 px-lg-6 ms-15 me-15 ms-lg-40 ms-xl-65 mt-12 mt-lg-0 mb-lg-30 mb-xl-40 lh-1',
              styles.content,
              {
                [styles.content_over_video_background_light]:
                  useDarkTextVideoColor,
                [styles.content_over_video_background_dark]:
                  !useDarkTextVideoColor,
              }
            )}
          >
            <div
              className={classNames({
                [styles.small]: useSmallHeading,
                [styles.large]: !useSmallHeading,
              })}
            >
              <HeaderTag
                className={classNames('font-freight fw-900 lh-1 b-10 mb-8', {
                  [styles.darkText]: useDarkText,
                  [styles.lightText]: !useDarkText,
                  [styles.videoDarkText]: useDarkTextVideoColor,
                  [styles.videoLightText]: !useDarkTextVideoColor,
                })}
              >
                {header}
              </HeaderTag>
            </div>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: xss(textContent) }}
              className={classNames('font-acumin text-primary mb-8', {
                [styles.darkText]: useDarkText,
                [styles.lightText]: !useDarkText,
                [styles.videoDarkText]: useDarkTextVideoColor,
                [styles.videoLightText]: !useDarkTextVideoColor,
              })}
            />
            {showCtaDiv && (
              <div className="d-flex flex-row">
                {ctaUrl && (
                  <Link
                    href={ctaUrl}
                    className={classNames(
                      'font-geo fw-600 bg-secondary text-primary text-uppercase',
                      styles.cta
                    )}
                  >
                    {ctaLabel}
                  </Link>
                )}
                {linkUrl && (
                  <Link
                    href={linkUrl}
                    className={classNames('font-acumin fw-400', linkStyle, {
                      [styles.darkText]: useDarkText,
                      [styles.lightText]: !useDarkText,
                      [styles.videoDarkText]: useDarkTextVideoColor,
                      [styles.videoLightText]: !useDarkTextVideoColor,
                    })}
                  >
                    {linkLabel}
                  </Link>
                )}
              </div>
            )}
          </div>
        )}
        <Modal
          centered
          size="lg"
          className={styles.modal}
          show={modalIsOpen}
          onHide={hideVideo}
        >
          <Modal.Header className="d-flex bg-transparent justify-content-end align-items-end p-0">
            <button
              type="button"
              className="btn btn-transparent text-white p-2"
              onClick={hideVideo}
            >
              <CloseIcon />
            </button>
          </Modal.Header>
          <Modal.Body className="p-0 w-100 bg-transparent">
            <Video
              autoPlay
              controls
              desktopSrc={videoSrc}
              mobileSrc={videoSrc}
            />
          </Modal.Body>
        </Modal>
      </div>
    </section>
  ) : null;
}

export default FullWidthVideo;
