/* eslint-disable complexity */
import { memo, useRef } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Image from 'components/Image';
import Link from 'components/Link';
import { sendLinkClickEvent } from 'utils/metrics';
import styles from './DynamicMediaCTA.module.scss';

const typeCtaMap = { NEW: 'See Product' };

function DynamicMediaCTA({
  id,
  cmsData,
  customCss,
  index,
  priority,
  productContent,
  sizes,
  ...rest
}) {
  const router = useRouter();
  const clickBorderRef = useRef(null);

  if (!cmsData?.length || !productContent) {
    return null;
  }

  const {
    dynamic_type: dynamicType,
    dynamic_background_media: dynamicBackgroundMedia,
    first_cta_color: ctaColor,
    first_cta_size: ctaSize,
    first_cta_type: ctaType,
    first_cta_theme: ctaTheme,
    header_color: headerColor,
    header_size: headerSize,
    header_type: headerType,
    subheader_size: subheaderSize,
    text_alignment: textAlignment,
    text_position: textPosition,
    text_overlay_background_color: textOverlayBackgroundColor,
  } = cmsData[0];

  const {
    brand: productBrand,
    name: productName,
    url: productUrl,
  } = productContent;

  const ctaText = typeCtaMap[dynamicType];

  const parentId = `mediaCta_${id}`;

  const handleClickEvent = (e, linkId, linkText, url) => {
    e.stopPropagation?.();
    sendLinkClickEvent(parentId, linkId, linkText, url);
  };

  const onTileBtnClick = (e) => {
    if ((productUrl ?? '') === '') {
      return;
    }
    e.preventDefault?.();
    clickBorderRef?.current?.classList.add(styles.button_content_clicked);
    handleClickEvent(e, 'tile', '', productUrl);

    if (productUrl) {
      router.push(productUrl);
    }
  };

  const onHeaderClick = (e) => {
    handleClickEvent(e, 'header', productBrand, productUrl);
  };

  const onCtaClick = (e) => {
    handleClickEvent(e, 'firstCta', ctaText, productUrl);
  };

  const { buttonTextAlignStyle, alignItemsClassName, isTextCentered } = (() => {
    switch (textAlignment) {
      case 'center': {
        return {
          buttonTextAlignStyle: styles.button_container_center_align,
          isTextCentered: true,
          alignItemsClassName: 'align-items-center',
        };
      }
      case 'right': {
        return {
          buttonTextAlignStyle: styles.button_container_right_align,
          isTextCentered: false,
          alignItemsClassName: 'align-items-end',
        };
      }
      default: {
        return {
          buttonTextAlignStyle: styles.button_container_left_align,
          isTextCentered: false,
          alignItemsClassName: 'align-items-start',
        };
      }
    }
  })();

  const justifyContentClassName = (() => {
    switch (textPosition) {
      case 'center': {
        return 'justify-content-center';
      }
      case 'bottom': {
        return 'justify-content-end';
      }
      default: {
        return 'justify-content-start';
      }
    }
  })();

  const { HeaderTag, SubheaderTag } = (() => {
    switch (headerType) {
      case 'h1': {
        return { HeaderTag: 'h1', SubheaderTag: 'h2' };
      }
      case 'h3': {
        return { HeaderTag: 'h3', SubheaderTag: 'h4' };
      }
      default: {
        return { HeaderTag: 'h2', SubheaderTag: 'h3' };
      }
    }
  })();

  const { headerSizeClassNames } = getMediaCtaSizeClassNames(headerSize);
  const { subheaderSizeClassNames } = getMediaCtaSizeClassNames(subheaderSize);
  const { ctaSizeClassNames } = getMediaCtaSizeClassNames(ctaSize);

  const applyPillStyleToCta = ctaType === 'pill';
  const textOverlayBgColorClassName = getOverlayBgColorClassName(
    textOverlayBackgroundColor
  );

  const { backgroundColor: ctaBackgroundColor, textColor: ctaTextColor } =
    getCtaTheme(ctaTheme);

  const headerColorClassName = styles[headerColor];
  const ctaColorClassName = applyPillStyleToCta
    ? ctaTextColor
    : styles[ctaColor];

  const renderHeaderSubheaderSection = () => {
    return (
      <>
        {productBrand && (
          <HeaderTag
            className={classNames(
              'font-freight m-0 pb-3 px-3 px-lg-5 mb-lg-6',
              headerSizeClassNames
            )}
          >
            {productBrand}
          </HeaderTag>
        )}
        {productName && (
          <SubheaderTag
            className={classNames(
              'font-geo m-0 pb-3 px-3 px-lg-5 text-uppercase',
              subheaderSizeClassNames
            )}
          >
            {productName}
          </SubheaderTag>
        )}
      </>
    );
  };

  return (
    <div
      id={`mediaCTA_${id}`}
      className={classNames('m-auto position-relative w-100 h-100', customCss)}
    >
      <Image
        fill
        priority={priority}
        src={dynamicBackgroundMedia?.url}
        alt={productName ?? productBrand ?? ctaText ?? ''}
        objectFit="cover"
        sizes={sizes}
        {...rest}
      />
      <div className={`position-absolute h-100 w-100 ${styles.overlay}`}>
        <button
          type="button"
          className={classNames(
            `m-0 p-0 border-0 bg-transparent h-100 w-100`,
            buttonTextAlignStyle,
            {
              [styles.default_cursor]: (productUrl ?? '') === '',
            }
          )}
          onClick={onTileBtnClick}
        >
          <div
            ref={clickBorderRef}
            className={classNames(
              'd-flex flex-column',
              alignItemsClassName,
              justifyContentClassName,
              buttonTextAlignStyle,
              headerColor === 'navy' ? 'border-primary' : 'border-white',
              styles.button_content
            )}
          >
            <div
              className={classNames(
                'pt-3 pt-lg-5 border-2',
                styles.text_overlay_background,
                (productUrl ?? '') !== '' && styles.header_subheader_decorator,
                headerColor === 'navy'
                  ? styles.header_subheader_decorator_primary
                  : styles.header_subheader_decorator_white,
                textOverlayBgColorClassName,
                ctaText && textAlignment !== 'center' && !applyPillStyleToCta
                  ? textAlignment === 'right'
                    ? styles.overlay_no_bottom_right_radius
                    : styles.overlay_no_bottom_left_radius
                  : styles.overlay_radius
              )}
            >
              {(productUrl ?? '') === '' ? (
                <div className={classNames(headerColorClassName)}>
                  {renderHeaderSubheaderSection()}
                </div>
              ) : (
                <Link
                  href={productUrl}
                  className={classNames(headerColorClassName)}
                  onClick={onHeaderClick}
                >
                  {renderHeaderSubheaderSection()}
                </Link>
              )}
            </div>
            <div
              className={classNames(
                styles.text_overlay_background,
                styles.sub_overlay_bottom_radius,
                {
                  [textOverlayBgColorClassName]: !applyPillStyleToCta,
                  'mt-4': applyPillStyleToCta,
                }
              )}
            >
              {ctaText ? (
                <div>
                  <div
                    className={classNames({
                      [`rounded-pill text-center ${ctaBackgroundColor}`]:
                        applyPillStyleToCta,
                      'mb-2': applyPillStyleToCta && !isTextCentered,
                      'me-2': applyPillStyleToCta && isTextCentered,
                    })}
                  >
                    <Link
                      href={productUrl}
                      className={classNames(ctaColorClassName, {
                        [styles.cta_decorator]: !applyPillStyleToCta,
                      })}
                      onClick={onCtaClick}
                    >
                      <p
                        className={classNames('m-0 pb-2', ctaSizeClassNames, {
                          'px-3 px-lg-5': !applyPillStyleToCta,
                          'px-5 px-lg-7': applyPillStyleToCta,
                        })}
                      >
                        {ctaText}
                      </p>
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}

function getMediaCtaSizeClassNames(textSize) {
  switch (textSize) {
    case 'large': {
      return {
        headerSizeClassNames:
          'fs-22 fs-sm-24 fs-md-28 fs-lg-24 fs-xl-28 fs-xxl-32',
        subheaderSizeClassNames:
          'fs-18 fs-sm-20 fs-md-24 fs-lg-20 fs-xl-24 fs-xxl-28',
        ctaSizeClassNames:
          'fs-12 fs-sm-14 fs-md-18 fs-lg-14 fs-xl-18 fs-xxl-22',
      };
    }
    case 'medium': {
      return {
        headerSizeClassNames:
          'fs-14 fs-sm-16 fs-md-20 fs-lg-16 fs-xl-20 fs-xxl-24',
        subheaderSizeClassNames:
          'fs-10 fs-sm-12 fs-md-16 fs-lg-12 fs-xl-16 fs-xxl-18',
        ctaSizeClassNames: 'fs-8 fs-md-12 fs-lg-8 fs-xl-12 fs-xxl-14',
      };
    }
    default: {
      return {
        headerSizeClassNames:
          'fs-10 fs-sm-14 fs-md-18 fs-lg-14 fs-xl-18 fs-xxl-20',
        subheaderSizeClassNames:
          'fs-7 fs-sm-12 fs-md-14 fs-lg-10 fs-xl-14 fs-xxl-15',
        ctaSizeClassNames: 'fs-6 fs-sm-7 fs-md-12 fs-lg-8 fs-xxl-9',
      };
    }
  }
}

function getCtaTheme(ctaTheme) {
  switch (ctaTheme) {
    case 'white': {
      return {
        backgroundColor: 'bg-white',
        textColor: 'text-primary',
      };
    }
    case 'gold': {
      return {
        backgroundColor: 'bg-secondary',
        textColor: 'text-primary',
      };
    }
    case 'cream': {
      return {
        backgroundColor: 'bg-cream',
        textColor: 'text-primary',
      };
    }
    case 'red': {
      return {
        backgroundColor: 'bg-alert',
        textColor: 'text-white',
      };
    }
    default: {
      return {
        backgroundColor: 'bg-primary',
        textColor: 'text-white',
      };
    }
  }
}

function getOverlayBgColorClassName(overlayBackgroundColor) {
  switch (overlayBackgroundColor) {
    case 'white': {
      return styles.text_overlay_background_white;
    }
    case 'cream': {
      return styles.text_overlay_background_cream;
    }
    case 'navy': {
      return styles.text_overlay_background_navy;
    }
    case 'gold': {
      return styles.text_overlay_background_gold;
    }
    default: {
      return '';
    }
  }
}

export default memo(DynamicMediaCTA);
